<template>
  <div>
    <v-row dense class="my-2">
      <v-col cols="12">
        <span class="title font-weight-bold">Billing Address</span>
      </v-col>
    </v-row>
    <v-row dense class="my-2">
      <v-col cols="12">
        <span class="subtitle-1 font-weight-bold">Recipient Info</span>
      </v-col>
    </v-row>
    <v-form lazy-validation ref="form">
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="name"
            type="text"
            label="Name *"
            dense
            autocomplete="off"
            v-model="billing_address.name"
            class="subtitle-1"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'name',
                value: billing_address.name,
              })
            "
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="company"
            type="text"
            label="Company *"
            autocomplete="off"
            dense
            class="subtitle-1"
            v-model="billing_address.company_name"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'company_name',
                value: billing_address.company_name,
              })
            "
            data-cy="rfq-billing-company-name"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="email"
                type="text"
                label="Email *"
                autocomplete="off"
                dense
                class="subtitle-1"
                v-model="billing_address.email"
                :readonly="isReadOnly"
                :rules="rules.requireCheck.concat(rules.emailCheck)"
                @change="
                  updateUIState({
                    key: 'email',
                    value: billing_address.email,
                  })
                "
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="phone"
                type="text"
                label="Phone *"
                autocomplete="off"
                dense
                class="subtitle-1"
                v-model="billing_address.phone"
                :readonly="isReadOnly"
                :rules="rules.requireCheck.concat(rules.phoneNumberCheck)"
                @change="
                  updateUIState({
                    key: 'phone',
                    value: billing_address.phone,
                  })
                "
                data-cy="rfq-billing-phone"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-autocomplete
            label="Country *"
            :items="country_list"
            item-text="name"
            item-value="code"
            v-model="billing_address.country"
            dense
            class="subtitle-1"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'country',
                value: billing_address.country,
              })
            "
            data-cy="rfq-billing-country"
          >
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12">
          <v-text-field
            name="street_name1"
            type="text"
            label="Street *"
            autocomplete="off"
            v-model="billing_address.street1"
            dense
            class="subtitle-1"
            :readonly="isReadOnly"
            :rules="rules.requireCheck"
            @change="
              updateUIState({
                key: 'street1',
                value: billing_address.street1,
              })
            "
            data-cy="rfq-billing-street1"
          />
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="street_name2"
                type="text"
                label="Street (line 2)"
                autocomplete="off"
                dense
                class="subtitle-1"
                v-model="billing_address.street2"
                :readonly="isReadOnly"
                @change="
                  updateUIState({
                    key: 'street2',
                    value: billing_address.street2,
                  })
                "
                data-cy="rfq-billing-street2"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="city"
                type="text"
                label="City *"
                autocomplete="off"
                v-model="billing_address.city"
                dense
                class="subtitle-1"
                :readonly="isReadOnly"
                :rules="rules.requireCheck"
                @change="
                  updateUIState({
                    key: 'city',
                    value: billing_address.city,
                  })
                "
                data-cy="rfq-billing-city"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="state"
                type="text"
                label="State *"
                autocomplete="off"
                v-model="billing_address.state"
                dense
                class="subtitle-1"
                :readonly="isReadOnly"
                :rules="rules.requireCheck"
                @change="
                  updateUIState({
                    key: 'state',
                    value: billing_address.state,
                  })
                "
                data-cy="rfq-billing-state"
              />
            </v-col>
          </v-row>
        </v-col>
        <v-col cols="12" md="6">
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                name="zip"
                type="text"
                label="Zip *"
                autocomplete="off"
                v-model="billing_address.zip"
                dense
                class="subtitle-1"
                :readonly="isReadOnly"
                :rules="rules.requireCheck"
                @change="
                  updateUIState({
                    key: 'zip',
                    value: billing_address.zip,
                  })
                "
                data-cy="rfq-billing-zip"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>
<script>
import country from "country-list";
export default {
  name: "BillingAddressForm",
  props: {
    editMode: {
      type: Boolean,
      default: false,
    },
    user: {
      type: Object,
      default: null,
    },
    company: {
      type: Object,
      default: null,
    },
    quote: {
      type: Object,
      default: null,
    },
    billingAddress: {
      type: Object,
      default: null,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      billing_address: {
        name:
          (this.editMode && this.billingAddress && this.billingAddress.name) ||
          (this.isReadOnly && this.billingAddress && this.billingAddress.name)
            ? this.billingAddress.name
            : this.quote && this.quote.user_details
            ? this.quote.user_details.full_name
            : this.user.full_name,
        street1:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.street1
            : null,
        street2:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.street2
            : null,
        city:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.city
            : null,
        state:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.state
            : null,
        zip:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.zip
            : null,
        country:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.country
            : null,
        company_name:
          (this.editMode &&
            this.billingAddress &&
            this.billingAddress.company_name) ||
          (this.isReadOnly &&
            this.billingAddress &&
            this.billingAddress.company_name)
            ? this.billingAddress.company_name
            : this.quote && this.quote.company_details
            ? this.quote.company_details.name
            : this.user.company
            ? this.user.company.completename
            : null,
        phone:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.phone
            : null,
        email:
          (this.editMode && this.billingAddress) ||
          (this.isReadOnly && this.billingAddress)
            ? this.billingAddress.email
            : this.quote && this.quote.user_details
            ? this.quote.user_details.email
            : this.user.email,
      },
      rules: {
        requireCheck: [(v) => !!v || this.isReadOnly || "Input is required"],
        emailCheck: [
          (v) => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return pattern.test(v) || this.isReadOnly || "Email is invalid";
          },
        ],
        phoneNumberCheck: [
          (v) =>
            Number.isInteger(Number(v)) ||
            this.isReadOnly ||
            "The value must be an integer number",
        ],
      },
      country_list: country.getData(),
    };
  },
  methods: {
    isBillingAddressValid() {
      return this.$refs.form.validate();
    },
    updateUIState(payload) {
      this.$store.dispatch("ui/updateBillingAddress", payload);
    },
    refreshAddressFields() {
      this.billing_address.name = this.billingAddress
        ? this.billingAddress.name
        : null;
      this.billing_address.street1 = this.billingAddress
        ? this.billingAddress.street1
        : null;
      this.billing_address.street2 = this.billingAddress
        ? this.billingAddress.street2
        : null;
      this.billing_address.city = this.billingAddress
        ? this.billingAddress.city
        : null;
      this.billing_address.state = this.billingAddress
        ? this.billingAddress.state
        : null;
      this.billing_address.zip = this.billingAddress
        ? this.billingAddress.zip
        : null;
      this.billing_address.country = this.billingAddress
        ? this.billingAddress.country
        : null;
      this.billing_address.company_name = this.billingAddress
        ? this.billingAddress.company_name
        : null;
      this.billing_address.phone = this.billingAddress
        ? this.billingAddress.phone
        : null;
      this.billing_address.email = this.billingAddress
        ? this.billingAddress.email
        : null;
      this.updateAllUIState();
    },
    updateAllUIState() {
      this.updateUIState({
        key: "name",
        value: this.billing_address.name,
      });
      this.updateUIState({
        key: "email",
        value: this.billing_address.email,
      });
      this.updateUIState({
        key: "company_name",
        value: this.billing_address.company_name,
      });
      this.updateUIState({
        key: "phone",
        value: this.billing_address.phone,
      });
      this.updateUIState({
        key: "street1",
        value: this.billing_address.street1,
      });
      this.updateUIState({
        key: "street2",
        value: this.billing_address.street2,
      });
      this.updateUIState({
        key: "city",
        value: this.billing_address.city,
      });
      this.updateUIState({
        key: "state",
        value: this.billing_address.state,
      });
      this.updateUIState({
        key: "zip",
        value: this.billing_address.zip,
      });
      this.updateUIState({
        key: "country",
        value: this.billing_address.country,
      });
    },
  },
  mounted() {
    this.updateAllUIState();
  },
};
</script>
